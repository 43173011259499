import React, {useCallback} from 'react';
import './App.css';
import styled from "styled-components";
import {SortOrder, useCreateStreakMutation, useGetStreaksQuery} from "./generated/gql/hooks";
import {Button, Group, Text} from "@mantine/core";

function App() {
    const {data, loading, error} = useGetStreaksQuery({
        variables: {
            take: 7,
            orderBy: {
                createdAt: "desc" as SortOrder
            }
        }
    });

    const [createStreak, {data: createData}] = useCreateStreakMutation();
    const handleCreateStreak = useCallback(() => {
        void createStreak({
            variables: {
                data: {
                    createdAt: new Date()
                }
            }
        })
    }, [createStreak]);

    if(!data){
        return <></>;
    }

    if(data.findManyStreaks.length === 0){
        data.findManyStreaks = [{
            createdAt: new Date(1),
            __typename: "Streaks",
            id: -1,
        }]
    }

    if (createData){
        debugger;
        window.location.reload();
    }

    const isStreakToday = new Date().getDate() === new Date(data?.findManyStreaks.at(-1)?.createdAt).getDate();

    const isStreakBroken = new Date().getDate() - 1 !== new Date(data?.findManyStreaks.at(-1)?.createdAt).getDate() - 1;

  return (
    <div className="App">
      <header className="App-header">
        <img src={isStreakBroken? 'broken-heart.png' :isStreakToday ? '/heart-succ.png' : '/heart.png'} className="App-logo" alt="logo" />
          <Group p={"xl"} position={"center"}>
          {
              Array.from({length: 7})
                  .map((_,i) => new Date().getDate() - i)
                  .reverse()
                  .map((currDay, index) => {
                      console.log(data?.findManyStreaks
                          .find(streak =>
                              new Date(streak.createdAt).getDate() === currDay
                          ))
                      return (
                          <Box key={index} streak={
                              (!!data?.findManyStreaks
                                  .find(streak =>
                                      new Date(streak.createdAt).getDate() === currDay
                                  )).toString()}>
                              {currDay}
                          </Box>
                      )
                  })
          }
          </Group>

          {
              isStreakToday ?
                  <Text>Bugün zaten streak yaptın, daha sonra tekrar gel!</Text>
                  : <Button color="red" size="md" onClick={handleCreateStreak}>Streak!</Button>
          }
      </header>
    </div>
  );
}

const Box = styled.div<{
    streak?: string
}>`
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props =>  props.streak === 'true' ? `
             background-color: #ff0000;
             color: #fff;
         `: `
             background-color: #ddff00;
             color: #000;
         `
  }
`;


export default App;
