import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type AggregateStreaks = {
  __typename?: 'AggregateStreaks';
  _count?: Maybe<StreaksCountAggregate>;
  _max?: Maybe<StreaksMaxAggregate>;
  _min?: Maybe<StreaksMinAggregate>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createStreaks?: Maybe<Streaks>;
  deleteManyStreaks?: Maybe<AffectedRowsOutput>;
  deleteStreaks?: Maybe<Streaks>;
  updateManyStreaks?: Maybe<AffectedRowsOutput>;
  updateStreaks?: Maybe<Streaks>;
  upsertStreaks?: Maybe<Streaks>;
};


export type MutationCreateStreaksArgs = {
  data: StreaksCreateInput;
};


export type MutationDeleteManyStreaksArgs = {
  where?: InputMaybe<StreaksWhereInput>;
};


export type MutationDeleteStreaksArgs = {
  where: StreaksWhereUniqueInput;
};


export type MutationUpdateManyStreaksArgs = {
  data: StreaksUpdateManyMutationInput;
  where?: InputMaybe<StreaksWhereInput>;
};


export type MutationUpdateStreaksArgs = {
  data: StreaksUpdateInput;
  where: StreaksWhereUniqueInput;
};


export type MutationUpsertStreaksArgs = {
  create: StreaksCreateInput;
  update: StreaksUpdateInput;
  where: StreaksWhereUniqueInput;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Query = {
  __typename?: 'Query';
  aggregateStreaks: AggregateStreaks;
  findFirstStreaks: Streaks;
  findManyStreaks: Array<Streaks>;
  findUniqueStreaks: Streaks;
  groupByStreaks: Array<StreaksGroupBy>;
};


export type QueryAggregateStreaksArgs = {
  cursor?: InputMaybe<StreaksWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StreaksOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StreaksWhereInput>;
};


export type QueryFindFirstStreaksArgs = {
  cursor?: InputMaybe<StreaksWhereUniqueInput>;
  distinct?: InputMaybe<Array<StreaksScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StreaksOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StreaksWhereInput>;
};


export type QueryFindManyStreaksArgs = {
  cursor?: InputMaybe<StreaksWhereUniqueInput>;
  distinct?: InputMaybe<Array<StreaksScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StreaksOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StreaksWhereInput>;
};


export type QueryFindUniqueStreaksArgs = {
  where: StreaksWhereUniqueInput;
};


export type QueryGroupByStreaksArgs = {
  by: Array<StreaksScalarFieldEnum>;
  having?: InputMaybe<StreaksScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StreaksOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StreaksWhereInput>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Streaks = {
  __typename?: 'Streaks';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
};

export type StreaksAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type StreaksCountAggregate = {
  __typename?: 'StreaksCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type StreaksCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type StreaksCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StreaksGroupBy = {
  __typename?: 'StreaksGroupBy';
  _count?: Maybe<StreaksCountAggregate>;
  _max?: Maybe<StreaksMaxAggregate>;
  _min?: Maybe<StreaksMinAggregate>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
};

export type StreaksMaxAggregate = {
  __typename?: 'StreaksMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type StreaksMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type StreaksMinAggregate = {
  __typename?: 'StreaksMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type StreaksMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type StreaksOrderByWithAggregationInput = {
  _avg?: InputMaybe<StreaksAvgOrderByAggregateInput>;
  _count?: InputMaybe<StreaksCountOrderByAggregateInput>;
  _max?: InputMaybe<StreaksMaxOrderByAggregateInput>;
  _min?: InputMaybe<StreaksMinOrderByAggregateInput>;
  _sum?: InputMaybe<StreaksSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type StreaksOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export enum StreaksScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type StreaksScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StreaksScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StreaksScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StreaksScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
};

export type StreaksSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type StreaksUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StreaksUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StreaksWhereInput = {
  AND?: InputMaybe<Array<StreaksWhereInput>>;
  NOT?: InputMaybe<Array<StreaksWhereInput>>;
  OR?: InputMaybe<Array<StreaksWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
};

export type StreaksWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateStreakMutationVariables = Exact<{
  data: StreaksCreateInput;
}>;


export type CreateStreakMutation = { __typename?: 'Mutation', createStreaks?: { __typename?: 'Streaks', createdAt: any, id: number } | null };

export type GetStreaksQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StreaksOrderByWithRelationInput> | StreaksOrderByWithRelationInput>;
}>;


export type GetStreaksQuery = { __typename?: 'Query', findManyStreaks: Array<{ __typename?: 'Streaks', id: number, createdAt: any }> };


export const CreateStreakDocument = gql`
    mutation CreateStreak($data: StreaksCreateInput!) {
  createStreaks(data: $data) {
    createdAt
    id
  }
}
    `;

/**
 * __useCreateStreakMutation__
 *
 * To run a mutation, you first call `useCreateStreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStreakMutation, { data, loading, error }] = useCreateStreakMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStreakMutation(baseOptions?: Apollo.MutationHookOptions<CreateStreakMutation, CreateStreakMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStreakMutation, CreateStreakMutationVariables>(CreateStreakDocument, options);
      }
export type CreateStreakMutationHookResult = ReturnType<typeof useCreateStreakMutation>;
export type CreateStreakMutationResult = Apollo.MutationResult<CreateStreakMutation>;
export type CreateStreakMutationOptions = Apollo.BaseMutationOptions<CreateStreakMutation, CreateStreakMutationVariables>;
export const GetStreaksDocument = gql`
    query GetStreaks($take: Int, $orderBy: [StreaksOrderByWithRelationInput!]) {
  findManyStreaks(take: $take, orderBy: $orderBy) {
    id
    createdAt
  }
}
    `;

/**
 * __useGetStreaksQuery__
 *
 * To run a query within a React component, call `useGetStreaksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreaksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreaksQuery({
 *   variables: {
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetStreaksQuery(baseOptions?: Apollo.QueryHookOptions<GetStreaksQuery, GetStreaksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreaksQuery, GetStreaksQueryVariables>(GetStreaksDocument, options);
      }
export function useGetStreaksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreaksQuery, GetStreaksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreaksQuery, GetStreaksQueryVariables>(GetStreaksDocument, options);
        }
export type GetStreaksQueryHookResult = ReturnType<typeof useGetStreaksQuery>;
export type GetStreaksLazyQueryHookResult = ReturnType<typeof useGetStreaksLazyQuery>;
export type GetStreaksQueryResult = Apollo.QueryResult<GetStreaksQuery, GetStreaksQueryVariables>;